<template>
        <section>
            <v-parallax :src="`${s3Path}joyfulWoman.png`" height="600" class="home-parallax" align-center justify-center  :class="{'mobile-layout' :is_screen_small}">
                <v-layout>
                    <v-row no-gutters>
                        <v-col lg="6" md="6" sm="12" xs="12">
                            <v-card height="100%" flat tile xs12 sm12  width="100%" column align="left" justify="left"  class="white--text parallax-text-holder transparent">
                                <v-card-text class="white--text subheading mb-3 text-xs-center parallax-text parralax-left">
                                  <span class="zamu-sub-heading trail-text">Power your </span>
                                  <span class="zamu-heading happiness-text happiness-purple">Growth </span>
                                  <span class="zamu-heading happiness-text mb-3">with Zamupay </span>

                                  <span class="image-text mb-8">Designed to eliminate growth bottlenecks associated with payment processing, empowering organizations and entrepreneurs.</span>
                                  <span class="" justify="space-around mb-8">
                                        <v-btn large depressed dark :color="`${zamuPurple2}`" href="/contact-sales">
                                             Get in Touch
                                        </v-btn>

                                  </span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col lg="6" md="6" sm="12" xs="12" class="hidden-sm-and-down">
                            <v-card flat tile height="100%"  width="70%" column align="right" justify="right"  class="white--text parallax-text-holder transparent">
                                <v-card-text class="white--text subheading text-xs-center parallax-text parallax-image" style="font-weight: 900; text-shadow: 2px 2px #000000;">
                                    <v-card tile flat class="image-holder transparent">
                                        <img :src="`${s3Path}purpleBlur.png`" height="200" class="">
                                        <img :src="`${s3Path}purpleRabbit.png`"  height="200" width="200" class="rabbit-img">
                                    </v-card>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-layout>
            </v-parallax>
        </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name:'Slider',
    mixins: [colorMixin, coreMixin],
    computed: {
        is_screen_small() {
        return this.$vuetify.breakpoint.smOnly || this.$vuetify.breakpoint.xsOnly
    },
    }

}
</script>

<style>

</style>